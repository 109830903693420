const HttpResponseErrorHandler = error => {
  const unauthorized =
    error.response.status === 401 || error.response.status === 403

  if (unauthorized) {
    return (window.location = '/nao-autorizado')
  }

  return Promise.reject(error)
}

export default HttpResponseErrorHandler
