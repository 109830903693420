//Actions
import {
  GET_LOTACAO,
  CHANGE_LOTACAO,
  GET_LOTACAO_PRINCIPAL
} from 'store/actionTypes'

// Apollo
import { lotacaoClient } from 'core/lotacao-config'
// Query
import { principal, allLotacoes } from 'graphs/lotacoes'
// Utils
import errorHandler from 'utils/errorHandler'

export const getLotacao = () => dispatch => {
  return lotacaoClient
    .query({ query: allLotacoes() })
    .then(({ data }) => {
      return dispatch({
        type: GET_LOTACAO,
        payload: data.lotacoes && data.lotacoes.items
      })
    })
    .catch(err => errorHandler(dispatch, err))
}

export const getPrincipal = () => dispatch => {
  return lotacaoClient
    .query({ query: principal() })
    .then(({ data }) => {
      const appInitials = window.appInitials.toLowerCase()
      localStorage.setItem(
        appInitials + '_lot',
        data.usuario.lotacao_principal.value
      )
      return dispatch({
        type: GET_LOTACAO_PRINCIPAL,
        payload: data.usuario
      })
    })
    .catch(err => errorHandler(dispatch, err))
}

export const changeLotacao = (data, lotacoes) => dispatch => {
  lotacoes.find(lotacao => lotacao.id === lotacao.title)
  return dispatch({
    type: CHANGE_LOTACAO,
    payload: data
  })
}
