import React from 'react'
// Material Components
import { withStyles } from '@material-ui/core'
// Bayon Components
import { Button } from '@bayon/commons'
// Global Components
import { ContainerDiv } from 'components'
// I18n
import { withI18n } from 'react-i18next'

export const Menu = ({ classes, onLogout, t }) => {
  return (
    <ContainerDiv className={classes.list}>
      <Button onClick={onLogout}>{t('Logout')}</Button>
    </ContainerDiv>
  )
}

const styles = {
  list: {
    margin: 0,
    padding: 0,
    listStyle: 'none'
  },
  button: {
    margin: 10,
    padding: 20
  }
}

export default withI18n()(withStyles(styles)(Menu))
