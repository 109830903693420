import React from 'react'
import PropTypes from 'prop-types'
import {
  FloatingMenu,
  MainButton,
  ChildButton
} from 'react-floating-button-menu'
import { Tooltip, withStyles } from '@bayon/commons'
import Icon from '@bayon/svg-icons'

export class FloatButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  render() {
    return (
      <FloatingMenu
        slideSpeed={500}
        direction="up"
        spacing={8}
        isOpen={this.state.isOpen}
      >
        <MainButton
          iconResting={
            <Tooltip
              enterDelay="50"
              color="white"
              placement="top"
              label="Ações"
              aria-label="Ações"
            >
              <Icon fill="white">EllipsisH</Icon>
            </Tooltip>
          }
          iconActive={
            <Tooltip
              enterDelay="50"
              color="white"
              placement="top"
              label="Fechar ações"
              aria-label="Fechar ações"
            >
              <Icon fill="white">Close</Icon>
            </Tooltip>
          }
          background="#0078D7"
          onClick={() => this.setState({ isOpen: !this.state.isOpen })}
          size={50}
          display="block"
        />
        <ChildButton
          icon={
            <Tooltip
              enterDelay="50"
              color="white"
              placement="top"
              label="Atualizar"
              aria-label="Atualizar"
            >
              <Icon fill="white">Sync</Icon>
            </Tooltip>
          }
          background="#0078D7"
          size={40}
          onClick={() => this.props.onReload()}
        />
        <ChildButton
          icon={
            <Tooltip
              enterDelay="50"
              color="white"
              placement="top"
              label="Tela inteira"
              aria-label="Tela inteira"
            >
              <Icon fill="white">Expand</Icon>
            </Tooltip>
          }
          background="#0078D7"
          size={40}
          onClick={() => this.props.onFullScreen()}
        />
        <ChildButton
          icon={
            <Tooltip
              enterDelay="50"
              color="white"
              placement="top"
              label="Limpar filtros"
              aria-label="Limpar filtros"
            >
              <Icon fill="white">Filter</Icon>
            </Tooltip>
          }
          background="#0078D7"
          size={40}
          onClick={() => this.props.onClearFilters()}
        />
      </FloatingMenu>
    )
  }
}

FloatButton.propTypes = {
  onReload: PropTypes.func,
  onFullScreen: PropTypes.func,
  onClearFilters: PropTypes.func
}

const styles = {
  float: {
    display: 'flex',
    justifyContent: 'flex-start',
    flex: 1,
    padding: '8px'
  }
}

export default withStyles(styles)(FloatButton)
