// Prototypes
import 'utils/prototypes/String'

// Screens
import Root from 'screens/Root'

// Routed
import Wrapper from 'screens/Wrapper'
import WrapperRoutes from 'screens/Wrapper/routes'

const baseRoutes = [
  {
    component: Root,
    routes: [
      {
        name: window.appName,
        path: '/',
        component: Wrapper,
        routes: WrapperRoutes
      }
    ]
  }
]

export default baseRoutes
