import React from 'react'

// Bayon Components
import { Loader, withStyles } from '@bayon/commons'
// Global Components
import { ContainerDiv } from 'components'

export const LoadingScreen = ({ classes }) => {
  return (
    <ContainerDiv className={classes.container}>
      <Loader size="medium" color="blue" label="Atualizando informações..." />
    </ContainerDiv>
  )
}

const styles = {
  container: {
    width: '100%',
    height: '78vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}

export default withStyles(styles)(LoadingScreen)
