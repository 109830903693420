import React from 'react'

// Bayon Components
import { withStyles, colors, IconButton, Typography } from '@bayon/commons'
// Global Components
import { ContainerDiv } from 'components'
// I18n
import { withI18n } from 'react-i18next'

export const UserHeader = ({ classes, onClick, t, ...props }) => {
  return (
    <ContainerDiv className={classes.container}>
      <IconButton
        aria-label={t('Fechar Sidebar')}
        size="medium"
        variant="subtle"
        onClick={onClick}
      >
        Close
      </IconButton>
      <Typography weight="semibold" color="neutralPrimary" size={18}>
        {t('Minha Conta')}
      </Typography>
    </ContainerDiv>
  )
}
const styles = {
  container: {
    height: 38,
    borderBottom: '1px solid ' + colors.neutralQuaternary,
    display: 'flex',
    width: 300,
    alignItems: 'center',
    padding: '0px 16px 0px 0px'
  }
}

export default withI18n()(withStyles(styles)(UserHeader))
