import React from 'react'
import { PageBuilder } from '@bayon/smarts'
import { powerBiClient } from 'core/powerbi-config'
import { Alert } from '@bayon/commons'
import { withI18n } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'

export class Workspaces extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMessage: false,
      message: ''
    }
  }

  showSuccessMessage = label => {
    if (label === 'Criar')
      this.setState({
        showMessage: true,
        message: 'Workspace criada com sucesso'
      })
    if (label === 'Salvar')
      this.setState({
        showMessage: true,
        message: 'Workspace editada com sucesso'
      })
    if (label === 'Deletar')
      this.setState({
        showMessage: true,
        message: 'Workspace deletada com sucesso'
      })
  }

  render() {
    return (
      <div className={this.props.classes.scroll}>
        <PageBuilder
          prefix="ins"
          metadataOrigin="Workspaces"
          onError={data => console.log(data)}
          actionName=""
          onNavigate={this.props.history.push}
          developerClient={powerBiClient}
          afterAction={(label, type, data) => {
            if (type.toLowerCase() === 'mutation')
            {
              this.showSuccessMessage(label)
            }
          }}
        />
        <Alert
          message={this.state.message}
          type="success"
          open={this.state.showMessage}
          onClose={() => this.setState({ showMessage: false })}
          autoHideDuration={1000}
        />
      </div>
    )
  }
}

const styles = {
  scroll: {
    padding: '16px',
    overflowY: 'scroll',
    maxHeight: 'calc(100vh - 70px)'
  }
}

export default (withI18n()(withStyles(styles)(Workspaces)))