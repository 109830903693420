import { SUCCESS_MESSAGE, ERROR_MESSAGE } from 'store/actionTypes'

const initialState = {
  isSuccess: null,
  message: '',
  refresh: null
}
export default (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_MESSAGE:
      return {
        ...state,
        isSuccess: true,
        message: action.payload,
        refresh: new Date()
      }
    case ERROR_MESSAGE:
      return {
        ...state,
        isSuccess: false,
        message: action.payload,
        refresh: new Date()
      }
    default:
      return { ...state }
  }
}
