import { UPDATE_CONFIGURACAO_POWER_BI } from 'store/actionTypes'

const initialState = {
  configuracaoPowerBi: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CONFIGURACAO_POWER_BI:
      return {
        ...state,
        configuracaoPowerBi: action.payload
      }
    default:
      return { ...state }
  }
}
