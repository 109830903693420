import React from 'react'

// Material
import { withStyles, Paper } from '@material-ui/core'

const PaperApp = withStyles({
  root: {
    height: '100vh',
    width: '100vw',
    display: 'block',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: 0
  }
})(Paper)

export const AppContainer = ({ children, ...props }) => {
  return (
    <PaperApp {...props} elevation={0}>
      {children}
    </PaperApp>
  )
}

export default AppContainer
