import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getCategories } from 'store/actions/categoria'
import { getWorkspaces } from 'store/actions/workspace'
import http from 'utils/request/index.js'
import FormData from 'form-data'
import DragArea from 'components/DragArea'
import { withI18n } from 'react-i18next'
import {
  withToast,
  withStyles,
  Action,
  ActionTitle,
  Button,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Select
} from '@bayon/commons'

export class ImportReport extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      files: [],
      isLoading: false,
      categoria: null,
      workspace: null,
      strategy: 'Ignore'
    }
  }

  componentDidMount = () => {
    this.props.onLoadGetCategorias()
    this.props.onLoadGetWorkspaces()
  }

  render() {
    return (
      <Action open={this.props.open}>
        <ActionTitle
          title="Importar Artefato(s)"
          onClose={() => {
            return this.props.onClose()
          }}
        />
        <div className={this.props.classes.scrollAction}>
          <DragArea
            onChange={(data, errorFiles) => {
              if (errorFiles.length > 0) {
                this.setState({ isLoading: false })
                this.props.enqueueToast(
                  'Arquivo(s) com tipos inválidos não puderam ser carregados',
                  { type: 'warning' }
                )
              }
              this.setState({ files: data })
            }}
            acceptType={this.getType()}
            height={100}
            onEnter={data => console.log(data)}
          />
          {this.state.files.length > 0 && this.showSelectedFiles()}
          {this.showCategoriesCombo()}
          {this.showWorkspacesCombo()}
          {this.showConflictCombo()}
          <div
            style={{
              padding: '32px 0px 16px 16px',
              display: 'flex',
              justifyContent: 'flex-end',
              flex: 1
            }}
          >
            <Button
              variant="cta"
              onClick={() => this.importFile()}
              loading={this.state.isLoading}
            >
              Importar
            </Button>
          </div>
        </div>
      </Action>
    )
  }

  importFile = () => {
    if (!this.state.categoria) {
      var message = ''
      if (
        !this.props.categorias ||
        !this.props.categorias.items ||
        this.props.categorias.items.length <= 0
      ) {
        message = 'você não tem permissão para visualizar nenhuma categoria'
      } else {
        message = 'Nenhuma categoria foi selecionada'
      }

      this.setState({ isLoading: false })
      this.props.enqueueToast(message, { type: 'error' })
      return
    }

    if (!this.state.workspace) {
      message = ''
      if (
        !this.props.workspaces ||
        !this.props.workspaces.items ||
        this.props.workspaces.items.length <= 0
      ) {
        message = 'você não tem permissão para visualizar nenhuma workspace'
      } else {
        message = 'Nenhuma workspace foi selecionado'
      }

      this.setState({ isLoading: false })
      this.props.enqueueToast(message, { type: 'error' })
      return
    }

    if (this.state.files.length <= 0) {
      this.setState({ isLoading: false })
      this.props.enqueueToast('Nenhum arquivo foi selecionado', {
        type: 'error'
      })
      return
    }

    this.setState({ isLoading: true })
    const formData = new FormData()
    for (var i = 0; i < this.state.files.length; i++) {
      formData.append('artefatos[' + i + '].Key', this.state.files[i].key)
      formData.append('artefatos[' + i + '].Value', this.state.files[i].value)
      formData.append('idCategoria', this.state.categoria)
      formData.append('idWorkspace', this.state.workspace)
      formData.append('strategy', this.state.strategy)
    }

    http
      .post(`${window.apiBaseUrl}/api/v1/artefatos/importar`, formData)
      .then(response => {
        this.setState({ isLoading: false, files: [] })
        return this.props.onSuccessImport(response)
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data[0]) {
          this.setState({ isLoading: false })
          this.props.enqueueToast(err.response.data[0].Message, {
            type: 'error'
          })
        } else {
          this.setState({ isLoading: false })
          this.props.enqueueToast(
            'Erro ao realizar importação, verifique o arquivo e tente novamente',
            { type: 'error' }
          )
        }
      })
  }

  removeFileFromList = index => {
    let array = this.state.files.slice()
    array.splice(index, 1)

    this.setState({
      files: array
    })
  }

  getType = () => {
    switch (this.props.type) {
      case 'relatorios':
        return 'spr'
      default:
        return ['pbix', 'spd']
    }
  }

  showConflictCombo = () => {
    var strategys = [
      {
        value: 'Ignore',
        label: 'Inserir com nome duplicado'
      },
      {
        value: 'Abort',
        label: 'Não inserir'
      },
      {
        value: 'CreateOrOverwrite',
        label: 'Substituir pelo novo'
      }
    ]
    return (
      <div>
        <br />
        <Select
          onChange={data => this.setState({ strategy: data.value })}
          label="O que fazer se houver conflito de mesmo nome"
          size="medium"
          required={true}
          options={strategys}
          defaultValue={strategys[0]}
        />
      </div>
    )
  }

  showSelectedFiles = () => {
    return (
      <Table size="small" checkable={false}>
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.files.map((item, index) => {
            return (
              <TableRow key={index} size="small">
                <TableCell>{item.key}</TableCell>
                <TableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flex: 1
                    }}
                  >
                    <Button
                      type="primary"
                      size="small"
                      icon="Trash"
                      onClick={() => this.removeFileFromList(index)}
                    >
                      Deletar
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }

  showCategoriesCombo = () => {
    if (!this.props.categorias || !this.props.categorias.items) {
      return
    }

    var categorias = []
    for (const item of this.props.categorias.items) {
      categorias.push({ label: item.nome, value: item.id })
    }
    return (
      <div>
        <br />
        <Select
          onChange={data => this.setState({ categoria: data.value })}
          label="Categoria"
          size="medium"
          required={true}
          options={categorias}
        />
      </div>
    )
  }

  showWorkspacesCombo = () => {
    if (!this.props.workspaces || !this.props.workspaces.items) {
      return
    }

    var workspaces = []
    for (const item of this.props.workspaces.items) {
      workspaces.push({ label: item.nome, value: item.id })
    }
    return (
      <div>
        <br />
        <Select
          onChange={data => this.setState({ workspace: data.value })}
          label="Workspace"
          size="medium"
          required={true}
          options={workspaces}
        />
      </div>
    )
  }
}

const styles = {
  scrollAction: {
    margin: '-16px',
    padding: '16px',
    overflowY: 'scroll',
    height: 'calc(100vh - 50px)'
  }
}

ImportReport.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  type: PropTypes.string.isRequired,
  onSuccessImport: PropTypes.func
}

export const mapStateToProps = ({ categoria, workspace }) => ({
  categorias: categoria.categorias,
  workspaces: workspace.workspaces
})

export const mapDispatchToProps = dispatch => ({
  onLoadGetCategorias: () => dispatch(getCategories()),
  onLoadGetWorkspaces: () => dispatch(getWorkspaces())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withI18n()(withStyles(styles)(withToast(ImportReport))))
