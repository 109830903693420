import React from 'react'
import { Switch, Route } from 'react-router-dom'

// Utils
import { queryStringToObject, routeArray } from 'utils'

export const ChildRoutes = props => {
  const routes = (props.routes || []).map((route, key) => (
    <Route
      key={key}
      location={props.location}
      exact={route.exact}
      path={route.path}
      render={routeProps => {
        const arrayRoute =
          props.location !== undefined && routeArray(props.location.pathname)

        const queryStringObject =
          props.location !== undefined &&
          queryStringToObject(props.location.search.replace(/\?/, ''))

        const composeMatch = {
          ...routeProps.match,
          likeArray: arrayRoute,
          queryParams: queryStringObject
        }
        const propRoutes = {
          ...routeProps,
          match: composeMatch
        }
        return (
          <route.component
            {...propRoutes}
            {...route}
            isLoading={props.isLoading}
            onLogout={props.onLogout}
          />
        )
      }}
    />
  ))

  return <Switch location={props.location}>{routes}</Switch>
}

ChildRoutes.defaultProps = {
  isLoading: false
}

export default ChildRoutes
