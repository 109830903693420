import { compose, applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import reducers from 'store/reducers'

const storeConfig = () => {
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose

  const middleware = composeEnhancers(applyMiddleware(thunk))
  return createStore(reducers, middleware)
}

export default storeConfig
