import { HomeComponent } from 'components'

// Screens
import Artefato from 'screens/Artefato'
import Artefatos from 'screens/Artefatos'
import Categorias from 'screens/Categorias'
import Workspaces from 'screens/Workspaces'

const routes = [
  {
    name: 'Home',
    path: '/',
    component: HomeComponent,
    exact: true
  },
  {
    name: 'Artefatos',
    path: `/artefatos/:type`,
    component: Artefatos,
    exact: true
  },
  {
    name: 'Artefato View',
    path: `/artefato/:action/:type/:id`,
    component: Artefato,
    exact: true
  },
  {
    name: 'Categorias',
    path: `/categorias`,
    component: Categorias,
    exact: true
  },
  {
    name: 'Workspaces',
    path: `/workspaces`,
    component: Workspaces,
    exact: true
  }
]

export default routes
