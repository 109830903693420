import React from 'react'

// Material Components
import { withStyles } from '@material-ui/core'
// Bayon Components
import { Avatar } from '@bayon/commons'
// Global Components
import { ContainerDiv } from 'components'

export const Client = ({ classes, name }) => {
  const letters = name ? name.replace(/^(\w{1})[\wçõãéíó]*\s(\w{1})[\wçõãéíó]*/, '$1$2') : ''
  return (
    <ContainerDiv className={classes.container}>
      <Avatar alt={letters} size="large" text={letters} />
    </ContainerDiv>
  )
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: 20,
  }
}

export default withStyles(styles)(Client)
