//Actions
import { GET_WORKSPACES } from 'store/actionTypes'

// Apollo
import { powerBiClient } from 'core/powerbi-config'
// Query
import { allWorkspaces } from 'graphs/workspaces'
// Utils
import errorHandler from 'utils/errorHandler'

//query
export const getWorkspaces = () => dispatch => {
  return powerBiClient
    .query({ query: allWorkspaces() })
    .then(({ data }) => {
      return dispatch({
        type: GET_WORKSPACES,
        payload: data.workspaces
      })
    })
    .catch(err => errorHandler(dispatch, err))
}
