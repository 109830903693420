import gql from 'graphql-tag'

//query
const allWorkspaces = () => gql`
  {
    workspaces(filter: {page_size: 100, page_number: 1}) {
      items {
        id
        nome        
      }
      page_info {
        current_page
        has_next
        has_previous
        page_size
      }
    }
  }
`

export { allWorkspaces }
