import React from 'react'
import { PageBuilder } from '@bayon/smarts'
import { powerBiClient } from 'core/powerbi-config'
import http from 'utils/request/index.js'
import { withI18n } from 'react-i18next'
import { ImportReport } from 'components'
import { withToast, withStyles } from '@bayon/commons'

export class Artefatos extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      isLoading: false,
      refreshComponent: false,
      categoria: null,
      workspace: null,
      strategy: 'Ignore',
      type: ''
    }
  }

  export = data => {
    var paramsUrl = data.substring(9, data.indexOf('_') - 1)
    var url = `${window.apiBaseUrl}/${paramsUrl}`

    http
      .get(`${url}`, {
        responseType: 'blob'
      })
      .then(response => {
        this.setState({ isLoading: false })
        this.props.enqueueToast('Exportação realizada com sucesso', {
          type: 'success'
        })

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(response.data)
        link.setAttribute('download', `${this.getFileName(data)}`)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      })
      .catch(err => {
        this.props.enqueueToast('Erro ao realizar exportação', {
          type: 'success'
        })
        this.setState({ isLoading: false })
      })
  }

  getFileName = data => {
    //parse no data para objeto e pegar pela propriedade que eu quero
    var name = data.substring(data.indexOf('_') + 1, data.indexOf('#'))
    var type = data.substring(data.indexOf('#') + 1, data.length)
    switch (type) {
      case 'Power BI':
        return `${name}.pbix`
      case 'Relatório':
        // return `${name}.spr`.replace(/ /g, '')
        return `${name}.spr`
      case 'Dashboard':
        return `${name}.spd`
      default:
        return ``
    }
  }

  visualizar = data => {
    //se nao for powerbi chamar o preview do reports
    //colocar em split e pegar pela posição (2)
    if (data.substring(20, 28).toUpperCase() !== 'POWER BI') {
      var tipo =
        data.substring(20, 30).toUpperCase() === 'RELATORIOS'
          ? 'Report'
          : 'Dashboards'
      var id = data.substring(31, 67)
      var viewMode = tipo === 'Report' ? 'Preview' : 'Show'
      window.open(`${window.apiBaseUrlReports}/${tipo}/${viewMode}/${id}`)
    } else {
      window.open(`${data}`)
    }
  }

  showActionMessage = (label, tipo) => {
    if (label === 'Exportar')
      this.props.enqueueToast('Processo de exportação em andamento', {
        type: 'info'
      })
    if (label === 'Deletar')
      this.props.enqueueToast('Artefato deletado com sucesso', {
        type: 'success'
      })
    if (label === 'Salvar' && tipo === 'Mutation')
      this.props.enqueueToast('Artefato salvo com sucesso', { type: 'success' })
    if (label === 'Salvar Cópia' && tipo === 'Mutation')
      this.props.enqueueToast('Cópia do artefato criada com sucesso', {
        type: 'success'
      })
  }

  showMessageError = ({ message }) => {
    this.setState({ isLoading: false })
    this.props.enqueueToast(message.substring(15, message.length), {
      type: 'error'
    })
  }

  navigatePageBuilder = data => {
    if (data.includes('importar')) {
      this.setState({
        open: true,
        type: this.props.location.pathname.substring(
          11,
          this.props.location.pathname.length
        )
      })
    } else if (data.includes('exportar')) {
      this.export(data)
    } else if (data.includes('design')) {
      if (data.includes('Power BI')) {
        window.open(`${data}`)
      }
    } else {
      this.visualizar(data)
    }
  }

  render() {
    return (
      <div className={this.props.classes.scroll}>
        <PageBuilder
          prefix="ins"
          metadataOrigin="artefatos"
          params={{
            ...this.props.match.params,
            ...this.state
          }}
          onError={this.showMessageError.bind(this)}
          developerClient={powerBiClient}
          actionName={'list_'.concat(this.props.match.params.type)}
          beforeEach={(label, tipo) => {
            if (label === 'Editar') return null
          }}
          afterAction={(label, type, data) =>
            this.showActionMessage(label, type)
          }
          onNavigate={data => this.navigatePageBuilder(data)}
        />
        <ImportReport
          open={this.state.open}
          type={this.state.type}
          onSuccessImport={data => this.successImport(data)}
          onClose={() =>
            this.setState({
              open: false
            })
          }
        />
      </div>
    )
  }

  successImport = response => {
    let mensagem = ''
    let tipo = ''
    let naoImportados = response.data.filter(data => !data.importado)
    if (naoImportados && naoImportados.length > 0) {
      mensagem = 'Relatório(s) não importado(s): '
      tipo = 'error'
      for (let i = 0; i < naoImportados.length; i++) {
        mensagem =
          i !== naoImportados.length - 1
            ? `${mensagem} ${naoImportados[i].nome}, \r\n`
            : `${mensagem} ${naoImportados[i].nome}`
      }
    } else {
      mensagem = 'Relatório(s) importado(s) com sucesso'
      tipo = 'success'
    }

    this.setState({ open: false })
    this.props.enqueueToast(mensagem, { type: tipo })
  }
}

const styles = {
  action: {
    width: '30vw'
  },
  alert: {
    width: '100px'
  },
  scroll: {
    padding: '16px',
    overflowY: 'scroll',
    height: 'calc(100vh - 50px)'
  }
}

export default withI18n()(withStyles(styles)(withToast(Artefatos)))
