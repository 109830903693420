import { GET_WORKSPACES } from 'store/actionTypes'

const initialState = {
  workspaces: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_WORKSPACES:
      return {
        ...state,
        workspaces: action.payload
      }

    default:
      return { ...state }
  }
}
