import React from 'react'
import { connect } from 'react-redux'
import Report from 'powerbi-report-component'
import { embedArtefato } from 'store/actions/artefato'
import { atualizatFiltro } from 'store/actions/configuracaoPowerBi'
import { Loading } from 'components'
import { models } from 'powerbi-client'
import { withStyles } from '@bayon/commons'
import { FloatButton } from 'components'
import jwt from 'jsonwebtoken'

export class Artefato extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      isLoading: false
    }

    this.report = null
    this.initialPageFilters = {}
    this.initialReportFilters = null
    this.pageFilters = {}
    this.reportFilters = []
    this.currentPage = null
    this.firstLoading = true
    this.firstChangePage = true
  }

  componentDidMount = () => {
    const { match: { params } } = this.props
    this.props.onLoadEmbedArtefato(params.id, params.type)
  }

  shouldComponentUpdate = () => {
    if (
      !this.props.data ||
      !this.props.data.artefato ||
      !this.props.data.artefato.access_token
    )
      return true

    return false
  }

  verifyShowMenu = permission => {
    var decoded = this.getClaims()
    return decoded.user_realm_roles.includes(permission)
  }

  getClaims = () => {
    var token = localStorage.getItem(
      window.appInitials.toLowerCase() + '_token'
    )
    return jwt.decode(token)
  }

  saveFilters = async () => {
    if (this.verifyShowMenu('app_painel_salvarfiltro_item')) {
      let jsonPagina = ''
      let jsonReport = ''
      if (this.pageFilters) {
        jsonPagina = JSON.stringify(this.pageFilters, null, 2)
      }
      if (this.reportFilters && this.reportFilters.length > 0) {
        jsonReport = JSON.stringify(this.reportFilters, null, 2)
      }

      let encodedPagina =
        jsonPagina !== '' ? JSON.stringify(jsonPagina).slice(1, -1) : ''
      let encodedReport =
        jsonReport !== '' ? JSON.stringify(jsonReport).slice(1, -1) : ''

      this.props.onUpdateFilter(
        this.props.data.artefato.id,
        encodedPagina,
        encodedReport
      )
    }
  }

  handlePageChange = async page => {
    if (!this.firstLoading && this.firstChangePage) {
      await this.setCurrentPage()
      await this.setPageFiltrers()
      this.firstChangePage = false
    }
  }

  clearFilters = () => {
    if (this.reportFilters && this.reportFilters.length > 0) {
      this.report.setFilters(this.initialReportFilters)
    }
    if (this.pageFilters) {
      // this.report.getPages().then(pages => {
      //   pages.forEach(page => {
      //     console.log('page', page)
      //     // if (this.pageFilters[page.displayName]) {
      //     //   page.setFilters(this.initialPageFilters[page.displayName])
      //     // }
      //   })
      // })

      this.currentPage.setFilters(
        this.initialPageFilters[this.currentPage.displayName]
      )
    }
  }

  setCurrentPage = () => {
    return new Promise((resolve, reject) => {
      resolve(
        this.report.getPages().then(pages => {
          this.currentPage = pages.filter(page => {
            return page.isActive
          })[0]
        })
      )
    })
  }

  getPageFiltrers = () => {
    return new Promise((resolve, reject) => {
      resolve(
        this.currentPage.getFilters().then(filters => {
          if (!this.initialPageFilters[this.currentPage.displayName]) {
            this.initialPageFilters[this.currentPage.displayName] = filters
          }
          this.pageFilters[this.currentPage.displayName] = filters
        })
      )
    })
  }

  setPageFiltrers = () => {
    return new Promise((resolve, reject) => {
      let filterCurrentPage = this.props.data.artefato.filtro_pagina.find(
        filterPage => filterPage.Key === this.currentPage.displayName
      )
      if (
        this.props.data.artefato.filtro_pagina &&
        this.props.data.artefato.filtro_pagina.length > 0 &&
        filterCurrentPage
      ) {
        let json = JSON.parse(filterCurrentPage.Value)
        resolve(this.currentPage.setFilters(json))
      } else {
        resolve()
      }
    })
  }

  getReportFiltrers = () => {
    return new Promise((resolve, reject) => {
      resolve(
        this.report.getFilters().then(filters => {
          if (!this.initialReportFilters) {
            this.initialReportFilters = filters
          }
          this.reportFilters = filters
        })
      )
    })
  }

  setReportFiltrers = () => {
    return new Promise((resolve, reject) => {
      if (
        this.props.data.artefato.filtro_report &&
        this.props.data.artefato.filtro_report !== ''
      ) {
        let json = JSON.parse(this.props.data.artefato.filtro_report)
        resolve(this.report.setFilters(json))
      } else {
        resolve()
      }
    })
  }

  handleReportRender = async () => {
    await this.setCurrentPage()
    await this.getPageFiltrers()
    await this.getReportFiltrers()

    if (this.firstLoading) {
      await this.setPageFiltrers()
      await this.setReportFiltrers()
      this.firstLoading = false
      if (this.props.match.params.action === 'design')
        this.report.switchMode('edit')
    } else {
      await this.saveFilters()
    }
  }

  render() {
    if (
      this.state.isLoading ||
      !this.props.data ||
      !this.props.data.artefato ||
      !this.props.data.artefato.access_token
    )
      return <Loading />

    return (
      <div className={this.props.classes.scroll}>
        <div className={this.props.classes.root}>
          <div className={this.props.classes.spacement}>
            <Report
              embedType="report"
              tokenType="Embed"
              accessToken={this.props.data.artefato.access_token}
              embedUrl={this.props.data.artefato.embed_url}
              embedId={this.props.data.artefato.id}
              extraSettings={{
                filterPaneEnabled: true,
                navContentPaneEnabled: true,
                localeSettings: {
                  language: 'pt',
                  formatLocale: 'br'
                },
                layoutType: models.LayoutType.Custom,
                customLayout: {
                  displayOption: models.DisplayOption.FitToWidth
                }
              }}
              permissions="All"
              style={{
                height: '100%',
                border: '0',
                padding: '0px',
                background: '#eee'
              }}
              onLoad={report => {
                this.report = report
              }}
              onRender={report => this.handleReportRender(report)}
              onPageChange={data => this.handlePageChange(data)}
            />
          </div>
          <div className={this.props.classes.floatButton}>
            <FloatButton
              onReload={() => this.report.reload()}
              onFullScreen={() => this.report.fullscreen()}
              onClearFilters={() => this.clearFilters()}
            />
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  scroll: {
    overflowY: 'scroll',
    height: 'calc(100vh - 50px)'
  },
  spacement: {
    float: 'left',
    width: '95%'
  },
  floatButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    paddingBottom: '40px',
    flex: 1,
    padding: '16px'
  }
}

export const mapStateToProps = ({ artefato, configuracaoPowerBi }) => ({
  data: artefato,
  dataConfiguracaoPowerBi: configuracaoPowerBi
})

export const mapDispatchToProps = dispatch => ({
  onLoadEmbedArtefato: (id, type) => dispatch(embedArtefato(id, type)),
  onUpdateFilter: (id, filtroPagina, filtroReport) =>
    dispatch(atualizatFiltro(id, filtroPagina, filtroReport))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Artefato))
