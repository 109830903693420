import { EMBED_ARTEFATO, REFRESH_LIST_ARTEFATO, FAVORITE_ARTEFATO } from 'store/actionTypes'

const initialState = {
  artefato: {},
  refreshComponent: false,
  favorite_artefato: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case EMBED_ARTEFATO:
      return {
        ...state,
        artefato: action.payload
      }
    case REFRESH_LIST_ARTEFATO:
      return {
        ...state,
        refreshComponent: action.payload
      }
    case FAVORITE_ARTEFATO:
      return {
        ...state,
        favorite_artefato: action.payload
      }
    default:
      return { ...state }
  }
}
