//Actions
import { EMBED_ARTEFATO, REFRESH_LIST_ARTEFATO } from 'store/actionTypes'

// Apollo
import { powerBiClient } from 'core/powerbi-config'
// Query
import { especificArtefato, favoriteArtefato } from 'graphs/artefatos'
// Utils
import errorHandler from 'utils/errorHandler'

//query
export const embedArtefato = (id, tipo) => dispatch => {
  return powerBiClient
    .query({ query: especificArtefato(id, tipo) })
    .then(({ data }) => {
      return dispatch({
        type: EMBED_ARTEFATO,
        payload: data.artefato_embedded
      })
    })
    .catch(err => errorHandler(dispatch, err))
}

export const changeFavoriteArtefato = (id, tipo) => dispatch => {
  return powerBiClient
    .query({ query: favoriteArtefato(id, tipo) })
    .then(({ data }) => {
      return dispatch({
        type: EMBED_ARTEFATO,
        payload: data.favorite_artefato
      })
    })
    .catch(err => errorHandler(dispatch, err))
}

export const refreshListArtefato = () => dispatch => {
  return dispatch({
    type: REFRESH_LIST_ARTEFATO,
    payload: true
  })
}