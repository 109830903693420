import React from 'react'

// Material
import { withStyles, Paper } from '@material-ui/core'
// Bayon Components
import { colors } from '@bayon/commons'

const PaperDiv = withStyles({
  root: {
    borderRadius: 0,
    backgroundColor: 'transparent',
    boxSizing: 'border-box',
    '&::-webkit-scrollbar': {
      width: 5
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      background: colors.defaultDark,
      borderRadius: 2
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: colors.neutralTertiary
    }
  }
})(Paper)

export const ContainerDiv = ({ children, ...props }) => {
  return (
    <PaperDiv {...props} elevation={0}>
      {children}
    </PaperDiv>
  )
}

export default ContainerDiv
