//Actions
import { GET_CATEGORIAS } from 'store/actionTypes'

// Apollo
import { powerBiClient } from 'core/powerbi-config'
// Query
import { allCategories } from 'graphs/categorias'
// Utils
import errorHandler from 'utils/errorHandler'

//query
export const getCategories = () => dispatch => {
  return powerBiClient
    .query({ query: allCategories() })
    .then(({ data }) => {
      return dispatch({
        type: GET_CATEGORIAS,
        payload: data.categorias
      })
    })
    .catch(err => errorHandler(dispatch, err))
}
