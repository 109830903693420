import { SUCCESS_MESSAGE, ERROR_MESSAGE } from 'store/actionTypes'

export const error = message => dispatch =>
  dispatch({
    type: ERROR_MESSAGE,
    payload: message
  })

export const success = message => dispatch =>
  dispatch({
    type: SUCCESS_MESSAGE,
    payload: message
  })
