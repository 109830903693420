import React from 'react'
import { PageBuilder } from '@bayon/smarts'
import { powerBiClient } from 'core/powerbi-config'
import { withI18n } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'

export class HomeComponent extends React.Component {
  render() {
    return (
      <div className={this.props.classes.space}>
        <PageBuilder
          prefix="ins"
          metadataOrigin="menu"
          onNavigate={this.props.history.push}
          onError={data => console.log(data)}
          developerClient={powerBiClient}
          actionName=""
        />
      </div>
    )
  }
}

const styles = {
  space: {
    padding: '16px',
    overflowY: 'scroll',
    height: 'calc(100vh - 50px)'
  }
}

export default (withI18n()(withStyles(styles)(HomeComponent)))