import React, { Component } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

// Redux
import { Provider } from 'react-redux'
import storeConfig from 'core/store'
// Bayon components
import { CssBaseline } from '@material-ui/core'
// Global Components
import { ChildRoutes } from 'components'
// Router
import baseRoutes from 'core/baseRoutes'
// Translate i18n
import 'core/i18n-config'
// Prototypes
import 'utils/prototypes/String'

const store = storeConfig()

class App extends Component {
  componentDidMount = () => {
    document.title = 'SAJ6 | ' + window.appName
  }

  render() {
    const basename = document.querySelector('base')
      ? document.querySelector('base').getAttribute('href')
      : null

    return (
      <Provider store={store}>
        <Router basename={basename || ''}>
          <>
            <CssBaseline />
            <ChildRoutes {...this.props} routes={baseRoutes} />            
          </>
        </Router>
      </Provider>
    )
  }
}

export default App
