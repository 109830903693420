import React from 'react'
import PropTypes from 'prop-types'
// Redux
import { connect } from 'react-redux'
// Bayon Components
import { Header, Toast, Typography } from '@bayon/commons'
// Global Components
import { ContainerApp, UserSidebar, ChildRoutes } from 'components'
//Material UI
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import jwt from 'jsonwebtoken'

export class Wrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpenUserSidebar: false,
      isOpenMessage: false,
      message: '',
      showMenu: false
    }
  }

  componentDidUpdate = prevProps => {
    if (this.props.refresh !== prevProps.refresh) {
      this.toggleToast(this.props.message)
    }
  }

  render() {
    const { onLogout, isSuccess, classes } = this.props
    const { isOpenUserSidebar, isOpenMessage, message } = this.state
    const letters = this.getClaims().preferred_username
      ? this.getClaims().preferred_username.replace(/^(\w{1})[\wçõãéíó]*\s(\w{1})[\wçõãéíó]*/, '$1$2')
      : ''

    return (
      <React.Fragment>
        <Toast
          message={message}
          type={isSuccess ? 'success' : 'error'}
          open={isOpenMessage}
          onClose={this.toggleToast.bind(this, message)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={3000}
        />
        <ContainerApp>
          <Header
            appName={window.appName}
            appInitials={window.appInitials}
            onClickMenu={this.toggleDrawer(true)}
            isActiveMenu={this.state.showMenu}
            onClickAvatar={this.toggleSidebar}
            isActiveAvatar={isOpenUserSidebar === true}
            avatarInitials={letters}
            avatarAlt={letters}
          />
          <div>
            <Drawer
              open={this.state.showMenu}
              onClose={this.toggleDrawer(false)}
              className={this.props.classes.drawer}
              classes={{ paper: classes.drawerPaper }}
            >
              {this.sideList(classes)}
            </Drawer>
          </div>
          {isOpenUserSidebar === true && (
            <UserSidebar
              isOpen={isOpenUserSidebar}
              onClick={this.toggleSidebar}
              onLogout={onLogout}
              history={this.props.history}
              userName={this.getClaims().preferred_username}
            />
          )}
          <ChildRoutes {...this.props} />
        </ContainerApp>
      </React.Fragment>
    )
  }

  sideList = classes => (
    <div
      className={classes.list}
      role="presentation"
      onClick={this.toggleDrawer(false)}
      onKeyDown={this.toggleDrawer(false)}
    >
      <div className={classes.drawerHeader}>
        <Typography className={classes.label2}>Menu</Typography>
      </div>
      <List>
        <ListItem button key="PaginaInicial" onClick={() => this.props.history.push('/')}>
          <Typography className={classes.label}>Pagina inicial</Typography>
        </ListItem>
        {this.verifyShowMenu('app_categoria_visualizar_menu') ? (
        <ListItem button key="Categorias" onClick={() => this.props.history.push(`/categorias`)}>
          <Typography className={classes.label}>Categorias</Typography>
        </ListItem>
        ) : null}
        {this.verifyShowMenu('app_workspace_visualizar_menu') ?
          <ListItem button key="Workspaces" onClick={() => this.props.history.push(`/workspaces`)}>
            <Typography className={classes.label}>Workspaces</Typography>
          </ListItem>
         : null}
         {this.verifyShowMenu('app_paineis_visualizar_menu') ? (
        <ListItem button key="Painéis" onClick={() => this.props.history.push(`/artefatos/paineis`)}>
          <Typography className={classes.label}>Painéis</Typography>
        </ListItem>
        ) : null}
        {this.verifyShowMenu('app_report_visualizar_menu') ? (
        <ListItem button key="Relatorios" onClick={() => this.props.history.push(`/artefatos/relatorios`)}>
          <Typography className={classes.label}>Relatórios</Typography>
        </ListItem>
        ) : null}
        {this.verifyShowMenu('app_pivot_visualizar_menu') ? (
        <ListItem button key="Pivot">
          <Typography className={classes.label}>Pivot</Typography>
        </ListItem>
        ) : null}
      </List>
    </div>
  )

  verifyShowMenu = permission => {
    var decoded = this.getClaims()
    return decoded.user_realm_roles.includes(permission)
  }

  getClaims = () => {
    var token = localStorage.getItem(window.appInitials.toLowerCase() + '_token')
    return jwt.decode(token)
  }

  toggleSidebar = () => {
    this.setState({ isOpenUserSidebar: !this.state.isOpenUserSidebar })
  }

  toggleToast = message => {
    this.setState({
      isOpenMessage: !this.state.isOpenMessage,
      message: message
    })
  }

  toggleDrawer = open => event => {
    this.setState({ showMenu: open })
  }
}

const styles = theme => ({
  label: {
    fontFamily:
      'Segoe UI", Selawik, Tahoma, Verdana, Arial, "sans-serif !default',
    color: 'rgb(197, 197, 197)',
    fontSize: '14px'
  },
  label2: {
    fontFamily:
      'Segoe UI", Selawik, Tahoma, Verdana, Arial, "sans-serif !default',
    color: 'rgb(166, 166, 166)',
    fontSize: '12px'
  },
  list: {
    width: 220
  },
  drawer: {
    width: 220,
    flexShrink: 0,
    top: 48
  },
  drawerPaper: {
    width: 220,
    top: 48,
    backgroundColor: '#4D4D4D'
  },
  drawerHeader: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
    padding: '0 18px',
    ...theme.mixins.toolbar
  }
})

export const mapStateToProps = ({ message, lotacao }) => ({
  isSuccess: message.isSuccess,
  message: message.message,
  refresh: message.refresh,
  user: lotacao.usuario
})

Wrapper.propTypes = {
  classes: PropTypes.object
}

export default connect(mapStateToProps)(withStyles(styles)(Wrapper))
