import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'

const authLink = setContext((_, { headers }) => {
  const appInitials = window.appInitials.toLowerCase()
  const token = localStorage.getItem(appInitials + '_token')
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const httpLink = createHttpLink({
  uri: `${window.apiBaseUrl}/api/graphql`
})

const cache = new InMemoryCache({
  addTypename: false
})

const defaultOptions = {
  fetchPolicy: 'network-only',
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
  }
}

const powerBiClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: cache,
  defaultOptions: defaultOptions
})

export { powerBiClient }
