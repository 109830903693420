import React from 'react'

// Material
import { Paper } from '@material-ui/core'
// Bayon
import { withStyles } from '@bayon/commons'

const PaperDiv = withStyles({
  root: {
    borderRadius: 0,
    boxSizing: 'border-box',
    position: 'absolute',
    right: 0,
    top: 48,
    bottom: 0,
    backgroundColor: 'transparent',
    height: '100%',
    width: '300px',
    overflow: 'hidden',
    zIndex: 2000,
  }
})(Paper)

export const Container = ({ children, ...props }) => {
  return (
    <PaperDiv {...props} elevation={0}>
      {children}
    </PaperDiv>
  )
}

export default Container
