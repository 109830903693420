// Messages
export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE'
export const ERROR_MESSAGE = 'ERROR_MESSAGE'

// User
export const GET_LOTACAO = 'GET_LOTACAO'
export const GET_LOTACAO_PRINCIPAL = 'GET_LOTACAO_PRINCIPAL'
export const CHANGE_LOTACAO = 'CHANGE_LOTACAO'

// List
export const LOADING_LIST = 'LOADING_LIST'
export const LOADED_LIST = 'LOADED_LIST'
export const LIST_DATA = 'LIST_DATA'
export const MUTATE_ACTION = 'MUTATE_ACTION'

// Artefatos
export const EMBED_ARTEFATO = 'EMBED_ARTEFATO'
export const REFRESH_LIST_ARTEFATO = 'EMBED_ARTEFATO'
export const FAVORITE_ARTEFATO = 'FAVORITE_ARTEFATO'

// Categoiras
export const GET_CATEGORIAS = 'GET_CATEGORIAS'

// Workspaces
export const GET_WORKSPACES = 'GET_WORKSPACES'

// Configuracao Power Bi
export const UPDATE_CONFIGURACAO_POWER_BI = 'UPDATE_CONFIGURACAO_POWER_BI'