import React from 'react'

// Material Components
import { withStyles, colors } from '@bayon/commons'
// Global Components
import { ContainerDiv } from 'components'

export const WhiteWrapper = ({ classes, isActive, children }) => (
  <ContainerDiv className={classes.container}>
    {isActive && <ContainerDiv className={classes.blueBar} />}
    <ContainerDiv className={classes.bodyCard}>{children}</ContainerDiv>
  </ContainerDiv>
)

const styles = {
  container: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    boxShadow: '0px 1px 2px ' + colors.neutralSecondaryAlt,
    marginBottom: 16,
    background: colors.white
  },
  blueBar: {
    height: '100%',
    width: 3,
    backgroundColor: colors.themePrimary + ' !important',
    position: 'absolute',
    zIndex: 1,
    opacity: 0,
    animationName: 'hideBar',
    animationDuration: '2s',
    animationTimingFunction: 'ease-in-out',
    animationDelay: '0s'
  },
  '@keyframes hideBar': {
    '0%': { opacity: 1 },
    '25%': { opacity: 0 },
    '75%': { opacity: 1 },
    '100%': { opacity: 0 }
  },
  '@keyframes borderColor': {
    '0%': {
      boxShadow: 'inset 0px 0px 0px 1px ' + colors.themePrimary
    },
    '25%': {
      boxShadow: 'inset 0px 0px 0px 2px ' + colors.neutralTertiary
    },
    '75%': {
      boxShadow: 'inset 0px 0px 0px 1px ' + colors.themePrimary
    },
    '100%': {
      boxShadow: 'inset 0px 0px 0px 2px ' + colors.neutralTertiary
    }
  },
  bodyCard: {
    width: '100%',
    height: '100%',
    padding: 16,
    boxSizing: 'border-box',
    position: 'relative'
  }
}

export default withStyles(styles)(WhiteWrapper)
