import { GET_CATEGORIAS } from 'store/actionTypes'

const initialState = {
  categorias: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIAS:
      return {
        ...state,
        categorias: action.payload
      }

    default:
      return { ...state }
  }
}
