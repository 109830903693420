import { combineReducers } from 'redux'

// Reducers
import messageReducer from './message'
import lotacaoReducer from './lotacao'
import artefatoReducer from './artefato'
import categoriaReducer from './categoria'
import workspaceReducer from './workspace'
import configuracaoPowerBiReducer from './configuracaoPowerBi'

const reducers = combineReducers({
  message: messageReducer,
  lotacao: lotacaoReducer,
  artefato: artefatoReducer,
  categoria: categoriaReducer,
  workspace: workspaceReducer,
  configuracaoPowerBi: configuracaoPowerBiReducer
})

export default reducers
