import React from 'react'
import ReactDOM from 'react-dom'
import App from 'core/App'
import { ToastProvider } from '@bayon/commons'

ReactDOM.render(
  <ToastProvider maxSnack={3}>
    <App />
  </ToastProvider>,
  document.getElementById('root')
)