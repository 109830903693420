import gql from 'graphql-tag'

const updateFilter = (id, filtroPagina, filtroReport) => gql`
  mutation {
  update_configuracao_power_bi(input: { id_artefato: "${id}", filtro_pagina: "${filtroPagina}",  filtro_report: "${filtroReport}"}) {
    id
  }
}
`

export { updateFilter }
