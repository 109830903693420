import React from 'react'
// Material Components
import { Slide } from '@material-ui/core'
// Bayon
import { withStyles } from '@bayon/commons'
// Global Components
import { ContainerDiv } from 'components'
// Components
import Container from './components/Container'
import Body from './components/Body'
import UserHeader from './components/UserHeader'
import Client from './components/Client'
import User from './components/User'
import Menu from './components/Menu'
// I18n
import { withI18n } from 'react-i18next'

export const UserSidebar = ({
  classes,
  isOpen,
  onClick,
  onLogout,
  userName
}) => {

  return (
    <Container>
      <Slide
        direction="left"
        in={isOpen}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 300, exit: 300 }}
      >
        <Body>
          <UserHeader onClick={onClick} />
          <Client name={userName} />
          <ContainerDiv>
            <User name={userName} />
            <ContainerDiv className={classes.content}>
            </ContainerDiv>
          </ContainerDiv>
          <ContainerDiv className={classes.menu}>
            <Menu onLogout={onLogout} />
          </ContainerDiv>
        </Body>
      </Slide>
    </Container>
  )
}
const styles = {
  menu: {
    marginLeft: 20,
    display: 'flex',
    flexDirection: 'flex-start'
  },
  content: {
    padding: 20,
    flex: 1
  }
}

export default (withI18n()(withStyles(styles)(UserSidebar)))
