// Action Types
import { LOADED_LIST } from 'store/actionTypes'
// Actions
import { error } from 'store/actions/message'

export default (dispatch, data) => {
  const { message } = data

  dispatch({ type: LOADED_LIST })

  const parsedMessage = message.replace(
    'GraphQL error: GraphQL.Validation.ValidationError:',
    ''
  )
  return dispatch(error(parsedMessage))
}
