export default pathname => {
  if (!pathname) {
    return null
  }

  if (pathname === '/') {
    return []
  }

  let parsedRoute = pathname.split('/')
  parsedRoute = parsedRoute.filter(item => item !== '')

  return parsedRoute
}
