import React from 'react'
import PropTypes from 'prop-types'

// Material Components
import { withStyles } from '@material-ui/core'
// Bayon Components
import { Loader } from '@bayon/commons'
// Global Components
import { ContainerApp } from 'components'
// I18n
import { withI18n } from 'react-i18next'

export const Loading = ({ classes, t }) => (
  <ContainerApp className={classes.centered}>
    <Loader size="large" label={t('Carregando')} />
  </ContainerApp>
)

const styles = {
  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}

Loading.propTypes = {
  t: PropTypes.func,
  classes: PropTypes.object
}

Loading.defaultProps = {
  t: t => t,
  classes: {}
}

export default withI18n()(withStyles(styles)(Loading))
