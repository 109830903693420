import React from 'react'

// Bayon Components
import { withStyles, Typography } from '@bayon/commons'
// Global Components
import { ContainerDiv } from 'components'

export const User = ({ classes, name }) => {
  return (
    <ContainerDiv className={classes.user}>
      <Typography className={classes.name}>
        {name}
      </Typography>
    </ContainerDiv>
  )
}
const styles = {
  user: {
    position: 'relative'
  },
  name: {
    display: 'block',
    color: '#5D5A58',
    fontSize: 18,
    paddingLeft: 20,
    fontWeight: 600
  }
}

export default withStyles(styles)(User)
