import gql from 'graphql-tag'

const principal = () => gql`
  {
    usuario {
      nome
      lotacao_principal {
        prefix: id_foro
        value: sequencial
        label: descricao
      }
    }
  }
`

const allLotacoes = () => gql`
  {
    lotacoes {
      items {
        prefix: id_foro
        value: sequencial
        label: descricao
      }
    }
  }
`

export { principal, allLotacoes }
