import {
  CHANGE_LOTACAO,
  GET_LOTACAO,
  GET_LOTACAO_PRINCIPAL
} from 'store/actionTypes'

const initialState = {
  list: [],
  usuario: {},
  lotacoes: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LOTACAO:
      return {
        ...state,
        list: action.payload
      }
    case GET_LOTACAO_PRINCIPAL:
      return {
        ...state,
        usuario: action.payload
      }
    case CHANGE_LOTACAO:
      return {
        ...state,
        list: action.payload
      }
    default:
      return { ...state }
  }
}
