import React from 'react'

// Redux
import { connect } from 'react-redux'
import { getPrincipal } from 'store/actions/lotacao'
// Single Sign On
import Keycloak from 'keycloak-js'
// Global Components
import { ChildRoutes, Loading } from 'components'

export class Root extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      keycloak: null,
      authenticated: false,
      appInitials: ''
    }
  }

  componentDidMount = async () => {
    this.setState({ appInitials: window.appInitials.toLowerCase() })
    //await this.props.onLoadPrincipal()
    const keycloak = Keycloak({
      realm: window.keyCloakRealm,
      url: window.keycloakBaseUrl,
      clientId: window.clientId
    })
    keycloak
      .init({
        onLoad: 'login-required',
        flow: 'implicit'
      })
      .success(authenticated => {
        this.onAuthenticated(keycloak, authenticated)
      })
  }

  componentDidUpdate = () => {
    // Enquanto não estiver setado no localStorage a lotação nenhuma tela aparecerá, pois
    // a lotação é responsável por todas as requisições do sistema.
    // const lot = localStorage.getItem(this.state.appInitials + '_lot')
    // if (!lot) {
    //   this.props.onLoadPrincipal()
    // }
  }

  render() {
    // const lot = localStorage.getItem(this.state.appInitials + '_lot')
    // const loadedLotacao = lot !== null

    return this.state.authenticated ? (
      <ChildRoutes {...this.props} onLogout={this.state.keycloak.logout} />
    ) : (
      <Loading />
    )
  }

  onAuthenticated = (keycloak, authenticated) => {
    localStorage.setItem(this.state.appInitials + '_token', keycloak.idToken)
    localStorage.setItem(this.state.appInitials + '_accessToken', keycloak.token)
    this.setState({
      keycloak: keycloak,
      authenticated: authenticated
    })
  }
}

export const mapStateToProps = ({ lotacao }) => ({
  user: lotacao.usuario
})

export const mapDispatchToProps = dispatch => ({
  onLoadPrincipal: () => dispatch(getPrincipal())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Root)
