//Actions
import { UPDATE_CONFIGURACAO_POWER_BI } from 'store/actionTypes'

// Apollo
import { powerBiClient } from 'core/powerbi-config'
// Query
import { updateFilter } from 'graphs/configuracaoPowerBi'
// Utils
import errorHandler from 'utils/errorHandler'

//query
export const atualizatFiltro = (id, filtroPagina, filtroReport) => dispatch => {
  return powerBiClient
    .mutate({ mutation: updateFilter(id, filtroPagina, filtroReport) })
    .then(({ data }) => {
      return dispatch({
        type: UPDATE_CONFIGURACAO_POWER_BI,
        payload: data.update_configuracao_power_bi
      })
    })
    .catch(err => errorHandler(dispatch, err))
}