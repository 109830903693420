import i18n from 'i18next'
import XHR from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-xhr-backend'
import { reactI18nextModule } from 'react-i18next'

// Dicts
import es from 'i18n/dict/es'
import pt from 'i18n/dict/pt'

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
    resources: {
      es: es,
      pt: pt
    },
    fallbackLng: 'pt',
    debug: false,
    saveMissing: false,
    interpolation: {
      escapeValue: false
    },
    react: {
      wait: false,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default'
    }
  })

export default i18n
