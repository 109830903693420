import axios from 'axios'
import HttpResponseSuccessHandler from './HttpResponseSuccessHandler'
import HttpResponseErrorHandler from './HttpResponseErrorHandler'

const http = axios.create()

const appInitials = window.appInitials.toLowerCase()
const token = localStorage.getItem(appInitials + '_token')
const lotacao = localStorage.getItem(appInitials + '_lot')

http.defaults.headers.common["Authorization"] = token ? `Bearer ${token}` : ''
http.defaults.headers.common["lotacao"] = lotacao ? lotacao : ''

http.interceptors.response.use(
  HttpResponseSuccessHandler,
  HttpResponseErrorHandler
)

export default http