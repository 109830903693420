import React from 'react'

// Material
import { Paper } from '@material-ui/core'
// Bayon Components
import { withStyles, colors } from '@bayon/commons'

const PaperDiv = withStyles({
  root: {
    boxSizing: 'border-box',
    backgroundColor: colors.white,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderLeft: '1px solid ' + colors.neutralQuaternary,
    borderRadius: 0
  }
})(Paper)

export const Body = ({ children, ...props }) => {
  return (
    <PaperDiv {...props} elevation={2}>
      {children}
    </PaperDiv>
  )
}

export default Body
