export default function queryStringToObject(qs) {
  return qs.split('&').reduce(function(accumulated, item) {
    const parts = item.split('=')

    if (parts[0] === 'historicId') {
      accumulated[parts[0]] = parseInt(parts[1])
    } else {
      accumulated[parts[0]] = parts[1]
    }

    return accumulated
  }, {})
}
