import React from 'react'
import PropTypes from 'prop-types'

// Bayon components
import { EmptyState, withStyles, colors } from '@bayon/commons'
// Utils
import Dropzone from 'react-dropzone'

export const DragArea = ({
  classes,
  onChange,
  height,
  enter,
  onEnter,
  acceptType
}) => {
  const className = `${classes.root} ${enter ? classes.focused : ''}`
  var dict = []

  const setOnLoad = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(
          dict.push({
            key: file.name,
            value: reader.result.replace(
              'data:application/octet-stream;base64,',
              ''
            )
          })
        )
      }

      reader.readAsDataURL(file)
    })
  }

  const handleChange = async files => {
    dict = []
    var errorFiles = []
    for (const file of files) {
      var type = file.name.substring(file.name.indexOf('.') + 1, file.name.length)
      if (acceptType.includes(type)) {
        await setOnLoad(file)
      } else {
        errorFiles.push(file)
      }
    }

    // if (dict.length > 0) {
      onChange(dict, errorFiles)
    //}
  }

  return (
    <Dropzone
      accept=".pbix, .spr, .spd"
      maxSize={1073741824}
      onDrop={handleChange}
      onDragEnter={() => onEnter(true)}
      onDragLeave={() => onEnter(false)}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps()}
          className={className}
          style={{ height: height }}
        >
          <input {...getInputProps()} />
          <EmptyState icon="FileAlt" title="Solte os arquivos aqui" />
        </div>
      )}
    </Dropzone>
  )
}

const styles = {
  root: {
    width: '100%',
    height: '100%',
    transition: '0.2s all',
    boxSizing: 'border-box',
    marginTop: 4,
    marginBottom: 8,
    '&:focus': { outline: 'none' },
    '&:active': {
      backgroundColor: colors.neutralLight,
      border: `4px dashed ${colors.neutralTertiary}`
    }
  },
  focused: {
    backgroundColor: colors.neutralLight,
    border: `4px dashed ${colors.neutralTertiary}`
  }
}

DragArea.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func,
  height: PropTypes.any,
  enter: PropTypes.bool,
  onEnter: PropTypes.func
}

export default withStyles(styles)(DragArea)
