import gql from 'graphql-tag'

//query
const especificArtefato = (id, tipo) => gql`
  {
    artefato_embedded(filter: { params: {id: "${id}" tipo: "${tipo}"} }) {
      id,
      embed_url,
      access_token,
      filtro_pagina,
      filtro_report
    }
  }
`

const favoriteArtefato = (id, tipo) => gql`
  mutation {
  favorite_artefato(input: { id: "${id}", tipo: "${tipo}" }) {
    id_artefato
    codigo_usuario
  }
}
`

export { especificArtefato, favoriteArtefato }
